@import '~antd/dist/antd.less';
@import (optional) '~@digatex/digatex-ui-lib/dist/@{theme}.less';

body {
  margin: 0;
  font-family: Calibri, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


header.ant-layout-header {
  background: @header-background;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 999;
}

header .ant-spin-blur::after {
  opacity: 0;
}

.site-layout-background {
  background: #fff;
}

#logo {
  height: 48px;
}

#home-button > svg {
  position: relative;
  top: calc(50% - 16px);
}


.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

@theme: digatex;